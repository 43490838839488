<!--
 * @Author: wuqi
 * @Date: 2021-09-07 16:31:59
 * @LastEditors: wuqi
 * @LastEditTime: 2021-11-12 15:09:09
 * @Description:
-->
<template>
  <div class='home-mail-box'>
    <card-header :title='menuData.name' :edit='edit' @deleteData='deleteData'  @editTheme='editTheme'  :menuData='menuData'>
      <template #right v-if='!edit' >
        <div >
          <en-icon  @click.native="toMyFolder" class="header-right-arrow" size="16" name="shouqi"></en-icon>
        </div>
      </template>
    </card-header>
    <div class='list-wrap' v-if="mailList.length">
      <mail-mod
        v-for="(itm, idx) in mailList"
        :home="true"
        :key="itm.id"
        :index="idx"
        :mailData="itm"
        :checkbox-visible="mailList.some((item) => item.checked)"
        usedFlg="list"
      ></mail-mod>
      <div class='more' v-if="hasNext" @click="addMore"><span>加载更多 <span v-if="releaseNum">({{releaseNum}})</span></span></div>
    </div>
    <en-result type="NoData" v-else></en-result>

  </div>
</template>

<script>
import { mailBoxService } from "@/api/mailBox/mailList/index";
import mailMod from "@/views/mailBox/views/List/components/mailMod";
import CardHeader from "./card-header";

export default {
  name: "MailBox",
  provide() {
    return {
      $mailList: this
    };
  },
  data() {
    return {
      loading: false,
      mailList: [],
      hasNext: false,
      seParams: { // 查未读的 条件
        mailAccount: "",
        type: 1,
        statuses: 1,
        readFlag: 1,
        pageNo: 1,
        pageSize: 10,
        folderType: "",
        // 不是未读邮件不需要statuses 和 type
        unread: true
      },
      proxyGetMailListFn: null, // 获取邮件的方法
      defaultreadPar: { // 默认账号的查询条件
          mailAccount: "",
          type: 1,
          statuses: 1,
          readFlag: 0,
          pageNo: 1,
          pageSize: 3,
          folderType: "1",
          unread: true
      },
      pageModel: {
        pageNo: 1,
        pageSize: 3,
        total: 0,
        totalPages: 0
      }
    };
  },
  props: {
    menuData: {
      type: Object
    },
    edit: {
      default: false
    }
  },

components: {
    mailMod, CardHeader
  },
  computed: {
  },
  created() {
    this.initQueryMailList();
  },
  mounted() {
  },
  methods: {
    deleteData() {
      this.$emit("deleteData");
    },
    editTheme() {
      this.$emit("editTheme");
    },
    toMyFolder() {
      this.$router.push({
        path: "/mailbox/list"
      });
    },
    async initQueryMailList() {
      await this.getDefaultAccount();
      this.proxyGetMailListFn = "queryMailList";
      this.queryMailList();
    },
    async getDefaultAccount() {
      const accounts = await mailBoxService.getMailAccount() || [];
      const defaultAccount = accounts.find((item) => item.isDefault === 0) || accounts?.[0];
      this.defaultreadPar.mailAccount = defaultAccount?.mailFullAccount || defaultAccount?.mailFullAccount || "";
    },
    async queryMailList() {
      try {
        this.loading = true;
        const searchParams = this.proxyGetMailListFn === "queryMailList" ? this.seParams : this.defaultreadPar;
        Object.assign(searchParams, this.pageModel);
        let params = { ...searchParams };
        if (searchParams.unread === false) {
          const { statuses, type, ...rest } = searchParams;
          params = rest;
        }
        const rsp = await mailBoxService.queryMailList(params);
        this.loading = false;
        this.mailList = [...this.mailList, ...rsp.records];
        this.hasNext = rsp.hasNext;
        if (!this.hasNext && this.proxyGetMailListFn === "queryMailList") {
          this.proxyGetMailListFn = "queryDefaultMailList";
          this.queryMailList();
        }
        this.pageModel.total = Number(rsp.total);
        this.pageModel.totalPages = rsp.totalPages;
        this.releaseNum = this.hasNext ? this.pageModel.total - ((this.pageModel.pageNo - 1) * this.pageModel.pageSize + rsp.records.length) : 0;
      } catch (error) {
        this.$message.error(error);
        this.loading = false;
      }
    },
    addMore() {
      this.pageModel.pageNo++;
      this.queryMailList();
    }
  }
};
</script>

<style lang='scss' scoped>
.home-mail-box{
  height: 100%;
  .list-wrap{
    height: calc(100% - 61px);
    overflow-y: auto;
  }
  .more{
    margin: 0 auto;
    /* display: inline-block; */
    text-align: center;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    span{
      cursor: pointer;
    }
  }
}
</style>
