var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-mail-box" },
    [
      _c("card-header", {
        attrs: {
          title: _vm.menuData.name,
          edit: _vm.edit,
          menuData: _vm.menuData,
        },
        on: { deleteData: _vm.deleteData, editTheme: _vm.editTheme },
        scopedSlots: _vm._u(
          [
            !_vm.edit
              ? {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        [
                          _c("en-icon", {
                            staticClass: "header-right-arrow",
                            attrs: { size: "16", name: "shouqi" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.toMyFolder.apply(null, arguments)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.mailList.length
        ? _c(
            "div",
            { staticClass: "list-wrap" },
            [
              _vm._l(_vm.mailList, function (itm, idx) {
                return _c("mail-mod", {
                  key: itm.id,
                  attrs: {
                    home: true,
                    index: idx,
                    mailData: itm,
                    "checkbox-visible": _vm.mailList.some(
                      (item) => item.checked
                    ),
                    usedFlg: "list",
                  },
                })
              }),
              _vm.hasNext
                ? _c(
                    "div",
                    { staticClass: "more", on: { click: _vm.addMore } },
                    [
                      _c("span", [
                        _vm._v("加载更多 "),
                        _vm.releaseNum
                          ? _c("span", [
                              _vm._v("(" + _vm._s(_vm.releaseNum) + ")"),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _c("en-result", { attrs: { type: "NoData" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }